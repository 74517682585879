.App {
  text-align: center;
  background-color: #952a08;
}

.App-header {
  background-color: #952a08;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.Znote {
  color: white;
  font-size: 12px;
}

a {
  color: #f26b21;
}
